"use client";

import type { TTestimonial } from "@/@types";
import React, { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";

import { cn } from "@acme/ui/lib/utils";

export const InfiniteMovingCards = ({
  items,
  direction = "left",
  speed = "fast",
  pauseOnHover = true,
  className,
}: {
  items: TTestimonial[];
  direction?: "left" | "right";
  speed?: "fast" | "normal" | "slow";
  pauseOnHover?: boolean;
  className?: string;
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const scrollerRef = React.useRef<HTMLUListElement>(null);

  useEffect(() => {
    addAnimation();
  }, []);
  const [start, setStart] = useState(false);
  function addAnimation() {
    if (containerRef.current && scrollerRef.current) {
      const scrollerContent = Array.from(scrollerRef.current.children);

      scrollerContent.forEach((item) => {
        const duplicatedItem = item.cloneNode(true);
        if (scrollerRef.current) {
          scrollerRef.current.appendChild(duplicatedItem);
        }
      });

      getDirection();
      getSpeed();
      setStart(true);
    }
  }
  const getDirection = () => {
    if (containerRef.current) {
      if (direction === "left") {
        containerRef.current.style.setProperty(
          "--animation-direction",
          "forwards",
        );
      } else {
        containerRef.current.style.setProperty(
          "--animation-direction",
          "reverse",
        );
      }
    }
  };
  const getSpeed = () => {
    if (containerRef.current) {
      if (speed === "fast") {
        containerRef.current.style.setProperty("--animation-duration", "20s");
      } else if (speed === "normal") {
        containerRef.current.style.setProperty("--animation-duration", "40s");
      } else {
        containerRef.current.style.setProperty("--animation-duration", "80s");
      }
    }
  };
  return (
    <div
      ref={containerRef}
      className={cn(
        "scroller relative z-20 overflow-hidden [mask-image:linear-gradient(to_right,transparent,white_20%,white_80%,transparent)]",
        className,
      )}
    >
      <ul
        ref={scrollerRef}
        className={cn(
          "flex w-max min-w-full shrink-0 flex-nowrap gap-4 py-4",
          start && "animate-scroll",
          pauseOnHover && "hover:[animation-play-state:paused]",
        )}
      >
        {items.map((item, idx) => (
          <li
            className="relative w-[350px] max-w-full flex-shrink-0 rounded-2xl border px-8 py-6 md:w-[450px] dark:border-slate-700"
            // style={{
            //   background:
            //     "linear-gradient(180deg, var(--slate-800), var(--slate-900)",
            // }}
            key={idx}
          >
            <blockquote>
              <div
                aria-hidden="true"
                className="user-select-none -z-1 pointer-events-none absolute -left-0.5 -top-0.5 h-[calc(100%_+_4px)] w-[calc(100%_+_4px)]"
              ></div>
              <Link href={item.linkedin} target="_blank">
                <div className="flex gap-x-3">
                  <Image
                    src={item.picture}
                    width={100}
                    height={100}
                    alt={item.name}
                    className="h-10 w-10 rounded-full shadow-sm"
                  />
                  <div>
                    <h3 className="text-sm font-normal leading-[1.6]">
                      {item.name}
                    </h3>
                    <h4 className="text-sm font-normal leading-[1.6] text-gray-400">
                      {item.position}
                    </h4>
                  </div>
                </div>
              </Link>
              <span className="relative z-20 text-sm font-normal leading-[1.6] dark:text-gray-100">
                {item.quote}
              </span>
              {/* <div className="relative z-20 mt-6 flex flex-row items-center">
                <span className="flex flex-col gap-1">
                  <span className=" text-sm font-normal leading-[1.6] text-gray-400">
                    {item.name}
                  </span>
                  <span className=" text-sm font-normal leading-[1.6] text-gray-400">
                    {item.position}
                  </span>
                </span>
              </div> */}
            </blockquote>
          </li>
        ))}
      </ul>
    </div>
  );
};
