"use client";

import React from "react";
import { useWaitListStore } from "@/store/use-whaitlist.store";

import { Button } from "@acme/ui/components/ui/button";

export default function WaitListButton() {
  const { setShowModal } = useWaitListStore();

  return <Button onClick={() => setShowModal(true)}>Get Started</Button>;
}
